/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { _Includes } from '../models/_Includes';
import type { HistoryRecord } from '../models/HistoryRecord';
import type { ListResponse } from '../models/ListResponse';
import type { Note } from '../models/Note';
import type { Property, SevDeskProperty } from '../models/Property';
import type { PropertyArea } from '../models/PropertyArea';

import { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

import { UUIDService } from './UUIDService';

export class PropertiesService {

    /**
     * Get a list of `Property`s.
     * @returns any A paginated list of `Property`s.
     * @throws ApiError
     */
    public static getProperties({
        include,
        pageSize,
        pageNumber,
        search,
        filter,
        sort,
        includeSevdesk,
        withoutIncludes,
    }: {
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Search string. **/
        search?: string,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
        /** Include SevDesk properties. **/
        includeSevdesk?: boolean,
        withoutIncludes?: boolean,
    }): CancelablePromise<(ListResponse & {
        data?: Array<Property>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/properties',
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'search': search,
                'filter': filter,
                'sort': sort,
                'includeSevdesk': includeSevdesk,
                'withoutIncludes': withoutIncludes,
            },
        });
    }

    /**
     * Get a list of `Property`s from SevDesk.
     * @returns any A list of `Property`s.
     * @throws ApiError
     */
    public static getSevDeskProperties(): CancelablePromise<(ListResponse & {
        data?: Array<Property>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sevdesk/properties',
        });
    }

    /**
     * Get a combined list of `Property`s from SevDesk and backend.
     * @returns any A list of `Property`s.
     * @throws ApiError
     */
    public static getCombinedProperties(): CancelablePromise<
    ListResponse & {
      data?: Array<Property>;
    }
  > {
    return new CancelablePromise(async (resolve, reject, onCancel) => {
      try {
        const sevDeskProperties: { data:  SevDeskProperty[] } = await __request(OpenAPI, {
          method: 'GET',
          url: '/sevdesk/properties',
          mediaType: 'application/json',
        });        

        // Format the propertiesResponse
        const formattedSevDeskPropertiesResponse = sevDeskProperties.data?.map((property: SevDeskProperty) => ({
            id: UUIDService.generateUUIDFromID(property.id),
            referenceNumber: property.id,
            customerReferenceNumber: property.contact.id,
            street: property.street ?? '/',
            name1: property.name ?? '/',
            postcode: property.zip ?? '/',
            city: property.city ?? '/',
            tags: [
                {
                  id: property.id,
                  name: 'SevDesk Object',
                },
              ],
        }));

        // Make another request to '/properties'
        const properties: { data: Property[] } = await __request(OpenAPI, {
          method: 'GET',
          url: '/properties',
          mediaType: 'application/json'
        });

        // Combine the responses
        const combinedResponse = {
          ...properties,
          data: [...formattedSevDeskPropertiesResponse, ...properties.data],
        };

        resolve(combinedResponse);
      } catch (error) {
        reject(error);
      }
    });
  }

   /**
     * Get a combined list of `Property`s from SevDesk and backend filtered by customerId.
     * @returns any A list of `Property`s.
     * @throws ApiError
     */
   public static getCombinedFilteredProperties({
    include,
    pageSize,
    pageNumber,
    search,
    filter,
    sort,
    includeSevdesk,
    withoutIncludes
}: {
    include?: Array<string>,
    pageSize?: number,
    pageNumber?: number,
    search?: string,
    filter?: any,
    sort?: Array<string>,
    includeSevdesk?: boolean,
    withoutIncludes?: boolean,
}): CancelablePromise<
ListResponse & {
  data?: Array<Property>;
}
> {
return new CancelablePromise(async (resolve, reject, onCancel) => {
  try {
    const referenceNumber = localStorage.getItem("referenceNumber");

    const sevDeskProperties: { data:  SevDeskProperty[] } = await __request(OpenAPI, {
      method: 'GET',
      url: `/sevdesk/customer/${referenceNumber}/properties`,
      mediaType: 'application/json',
    });        

    // Format the propertiesResponse
    const formattedSevDeskPropertiesResponse = sevDeskProperties.data?.map((property: SevDeskProperty) => ({
        id: UUIDService.generateUUIDFromID(property.id),
        referenceNumber: property.id,
        customerReferenceNumber: property.contact.id,
        street: property.street ?? '/',
        name1: property.name ?? '/',
        postcode: property.zip ?? '/',
        city: property.city ?? '/'
    }));

    // Make another request to '/properties'
    const properties: { data: Property[] } = await __request(OpenAPI, {
      method: 'GET',
      url: '/properties',
      mediaType: 'application/json',
      query: {
        'include': include,
        'page[size]': pageSize,
        'page[number]': pageNumber,
        'search': search,
        'filter': filter,
        'sort': sort,
        'withoutIncludes': withoutIncludes,
        'includeSevdesk': includeSevdesk,
    },
    });

    // Combine the responses
    const combinedResponse = {
      ...properties,
      data: [...formattedSevDeskPropertiesResponse, ...properties.data],
    };

    resolve(combinedResponse);
  } catch (error) {
    reject(error);
  }
});
}

    /**
     * Create a new `Property`.
     * @returns any A single `Property`.
     * @throws ApiError
     */
    public static addProperty({
        requestBody,
    }: {
        /** A `Property` JSON object. **/
        requestBody?: Property,
    }): CancelablePromise<{
        data?: Property;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/properties',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get a single `Property` identified by the given ID.
     * @returns any A single `Property`.
     * @throws ApiError
     */
    public static getPropertyById({
        id,
        include,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
    }): CancelablePromise<{
        data?: Property;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/properties/{id}',
            path: {
                'id': id,
            },
            query: {
                'include': include,
            },
        });
    }

    /**
     * Get a single `Property` identified by the given ID and merge sevdesk data.
     * @returns any A single `Property`.
     * @throws ApiError
     */
    public static getCombinedPropertyById({
        id,
        sevDeskId,
        include,
    }: {
        /** ID of the resource. **/
        id: string,
        /** ID of the SevDesk resource (if available). **/
        sevDeskId?: string | undefined,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
    }): CancelablePromise<{
        data?: Property;
        includes?: _Includes;
    }> {
        return new CancelablePromise(async (resolve, reject, onCancel) => {
            try {
                const promises: Promise<any>[] = [];
                const propertyRequest = __request(OpenAPI, {
                    method: 'GET',
                    url: '/properties/{id}',
                    path: {
                        'id': id,
                    },
                    query: {
                        'include': include,
                    },
                });
                promises.push(propertyRequest);
    
                if (sevDeskId !== undefined) {
                    const sevDeskRequest = __request(OpenAPI, {
                        method: 'GET',
                        url: `/sevdesk/properties/${sevDeskId}`,
                    });
                    promises.push(sevDeskRequest);
                }
    
                const [propertyResponse, sevDeskResponse] = await Promise.all(promises);

                if (sevDeskResponse && sevDeskResponse.data) {
                    const mergedData = {
                        ...propertyResponse, 
                        data: {
                            ...propertyResponse.data,
                            street: sevDeskResponse.data.street,
                            postcode: sevDeskResponse.data.zip,
                            city: sevDeskResponse.data.city,
                            name1: sevDeskResponse.data.name ?? '/'
                        }
                    }

                    resolve(mergedData);
                }
    
                resolve(propertyResponse);
            } catch (error) {
                reject(error);
            }
        });
    }

    /**
     * Update the `Property` identified by the given ID.
     * @returns any A single `Property`.
     * @throws ApiError
     */
    public static updateProperty({
        id,
        requestBody,
    }: {
        /** ID of the resource. **/
        id: string,
        /** The `Property` object data to update.
         * - Send changed attributes only to minimize the risk of collisions during parallel updates. **/
        requestBody?: Property,
    }): CancelablePromise<{
        data?: Property;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/properties/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete the `Property` identified by the given ID.
     * @returns void
     * @throws ApiError
     */
    public static deleteProperty({
        id,
        force,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Erzwingt die Durchführung des Requests, zum Beispiel endgültige Löschung
         * von soft deleted Entitäten.
         *
         * Aktiviert durch die Werte `true`, `1` oder `yes`. **/
        force?: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/properties/{id}',
            path: {
                'id': id,
            },
            query: {
                'force': force,
            },
        });
    }

    /**
     * Get a paginated list of `HistoryRecord`s.
     * @returns any A paginated list of `HistoryRecord`s.
     * @throws ApiError
     */
    public static getPropertyHistory({
        id,
        include,
        pageSize,
        pageNumber,
        filter,
        sort,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<HistoryRecord>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/properties/{id}/history',
            path: {
                'id': id,
            },
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
            },
        });
    }

    /**
     * @deprecated
     * Get a paginated list of `Note`s.
     * @returns any A paginated list of `Note`s.
     * @throws ApiError
     */
    public static getPropertyNotes({
        id,
        include,
        pageSize,
        pageNumber,
        filter,
        sort,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<Note>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/properties/{id}/notes',
            path: {
                'id': id,
            },
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
            },
        });
    }

    /**
     * Get a list of `PropertyArea`s.
     * @returns any A paginated list of `Property`s.
     * @throws ApiError
     */
    public static getPropertyAreas({
        include,
        pageSize,
        pageNumber,
        filter,
        sort,
    }: {
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
        /** Number of items to return per page. **/
        pageSize?: number,
        /** Number of the page to load. **/
        pageNumber?: number,
        /** Filteroptionen. **/
        filter?: any,
        /** Sortierparameter.
         *
         * Es kann nach mehreren Attributen nacheinander sortiert werden. Dazu
         * werden die zu verwendenen Attribute als Komma-getrenntes Array
         * übergeben.
         *
         * Standardmäßig wird aufsteigend sortiert. Um die Reihenfolge umzukehren,
         * kann ein Minus vor den Attributnamen gesetzt werden.
         *
         * Beispiel: `?sort=-birthday,lastname` => Jüngste zuerst, Personen mit
         * gleichem Geburtstag werden nach Name sortiert.
         *  **/
        sort?: Array<string>,
    }): CancelablePromise<(ListResponse & {
        data?: Array<PropertyArea>;
    })> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/property-areas',
            query: {
                'include': include,
                'page[size]': pageSize,
                'page[number]': pageNumber,
                'filter': filter,
                'sort': sort,
            },
        });
    }

    /**
     * Create a new `PropertyArea`.
     * @returns any A single `Property`.
     * @throws ApiError
     */
    public static addPropertyArea({
        requestBody,
    }: {
        /** A `PropertyArea` JSON object. **/
        requestBody?: PropertyArea,
    }): CancelablePromise<{
        data?: PropertyArea;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/property-areas',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get a single `PropertyArea` identified by the given ID.
     * @returns any A single `Property`.
     * @throws ApiError
     */
    public static getPropertyAreaById({
        id,
        include,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Die mit den Antwortobjekten verknüpften Entitäten der hier übergebenen
         * Entitätstypen liefert der Server in einem Attribut `include` im
         * Antwortobjekt mit, so dass für diese keine weiteren Anfragen notwendig
         * werden.
         * Beispiel: `?include=Customer,Employee,Property,Customer`
         *  **/
        include?: Array<string>,
    }): CancelablePromise<{
        data?: PropertyArea;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/property-areas/{id}',
            path: {
                'id': id,
            },
            query: {
                'include': include,
            },
        });
    }

    /**
     * Update the `PropertyArea` identified by the given ID.
     * @returns any A single `Property`.
     * @throws ApiError
     */
    public static updatePropertyArea({
        id,
        requestBody,
    }: {
        /** ID of the resource. **/
        id: string,
        /** The `PropertyArea` object data to update.
         * - Send changed attributes only to minimize the risk of collisions during parallel updates. **/
        requestBody?: PropertyArea,
    }): CancelablePromise<{
        data?: PropertyArea;
        includes?: _Includes;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/property-areas/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete the `PropertyArea` identified by the given ID.
     * @returns void
     * @throws ApiError
     */
    public static deletePropertyArea({
        id,
        force,
    }: {
        /** ID of the resource. **/
        id: string,
        /** Erzwingt die Durchführung des Requests, zum Beispiel endgültige Löschung
         * von soft deleted Entitäten.
         *
         * Aktiviert durch die Werte `true`, `1` oder `yes`. **/
        force?: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/property-areas/{id}',
            path: {
                'id': id,
            },
            query: {
                'force': force,
            },
        });
    }

}